<template>
  <v-container fill-height>
    <v-row justify="center" align="center" dense>
      <v-col cols="12">
        <v-alert type="info">资料状态: {{ infoStatus }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title> 基本信息/Basic Info </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.basic" :key="field">
              <v-list-item-title
                :class="userCampInfo[field] ? '' : 'red--text'"
                v-text="desc"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="field == 'gender' ? genderList[userCampInfo[field]] : userCampInfo[field]"
              ></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title> 联系信息/Contact Info </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.contact" :key="field">
              <v-list-item-title
                :class="userCampInfo[field] ? '' : 'red--text'"
                v-text="desc"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="(field == 'phone' ? '+86 ' : '') + userCampInfo[field]"
              ></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title> 学校信息/University Info </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.school" :key="field">
              <v-list-item-title
                :class="userCampInfo[field] || field == 'is_special_plan' ? '' : 'red--text'"
                v-text="desc"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo[field]"></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title> 专业信息/Degree Info </v-card-title>
          <v-card-text>
            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.major_cn ? '' : 'red--text'"
                v-text="campInfoFields.degree.major_cn"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_cn"></v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.major_en ? '' : 'red--text'"
                v-text="campInfoFields.degree.major_en"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_en"></v-list-item-subtitle>
            </v-list-item>

            <div v-for="item in degreeTypes" :key="item">
              <div v-if="userCampInfo['has_' + item + '_degree']">
                <v-list-item>
                  <v-list-item-title
                    v-text="campInfoFields.degree[item + '_degree_cn']"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="userCampInfo[item + '_degree_cn']"
                  ></v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    v-text="campInfoFields.degree[item + '_degree_en']"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="userCampInfo[item + '_degree_en']"
                  ></v-list-item-subtitle>
                </v-list-item>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title> 成绩信息/Ranking Info </v-card-title>
          <v-card-text>
            <v-list-item v-if="userCampInfo.overall_gpa_ranking">
              <v-list-item-title
                v-text="campInfoFields.ranking.overall_gpa_ranking"
                class="text-wrap"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.overall_gpa_ranking">
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="userCampInfo.major_gpa_ranking">
              <v-list-item-title
                v-text="campInfoFields.ranking.major_gpa_ranking"
                class="text-wrap"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_gpa_ranking"> </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.major_students ? '' : 'red--text'"
                v-text="campInfoFields.ranking.major_students"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_students"></v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title v-text="campInfoFields.ranking.special_notes"></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.special_notes"></v-list-item-subtitle>
            </v-list-item>

            <v-data-table
              :headers="curriculumHeaders"
              :items="coreCurriculum"
              hide-default-footer
              disable-pagination
              light
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    v-text="campInfoFields.curriculum.core_curriculum"
                  ></v-toolbar-title>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title :class="userCampInfo.personal_statement ? '' : 'red--text'">
            个人简述/Personal statement
          </v-card-title>
          <v-card-text>
            <pre v-bind:style="preStyle" v-text="userCampInfo.personal_statement"></pre>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title> 推荐信息/Recommend Info </v-card-title>
          <v-card-text>
            <div v-for="item in recommanderTypes" :key="item">
              <v-list-item>
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_name']"
                  :class="
                    userCampInfo['recommander_' + item + '_name'] || item == 'c'
                      ? 'text-wrap'
                      : 'text-wrap red--text'
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="userCampInfo['recommander_' + item + '_name']"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_phone']"
                  :class="
                    userCampInfo['recommander_' + item + '_phone'] || item == 'c'
                      ? 'text-wrap'
                      : 'text-wrap red--text'
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="userCampInfo['recommander_' + item + '_phone']"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_email']"
                  :class="
                    userCampInfo['recommander_' + item + '_email'] || item == 'c'
                      ? 'text-wrap'
                      : 'text-wrap red--text'
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="userCampInfo['recommander_' + item + '_email']"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="userCampInfo['recommander_' + item + '_email']">
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_letter']"
                >
                </v-list-item-title>
                <v-list-item-subtitle
                  v-text="recommendLetterStatus(item)"
                  class="text-wrap"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-divider></v-divider>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title> 上传材料/Upload </v-card-title>
          <v-card-text>
            <v-list-item v-for="(item, field) in campInfoFiles" :key="field">
              <v-list-item-title
                :class="
                  userCampInfo[field] || field == 'other_docs' ? 'text-wrap' : 'text-wrap red--text'
                "
                v-text="item.desc"
              ></v-list-item-title>
              <v-list-item-subtitle v-if="userCampInfo[field]">
                <v-chip color="success">
                  <v-icon left> mdi-paperclip </v-icon>
                  {{ userFiles[field] ? userFiles[field].name : "" }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title> 其他信息/Others </v-card-title>
          <v-card-text>
            <div v-for="(desc, field) in campInfoFields.other" :key="field">
              <v-list-item>
                <v-list-item-title class="text-wrap" v-text="desc"></v-list-item-title>
                <v-list-item-subtitle>
                  <pre v-bind:style="preStyle" v-text="userCampInfo[field]"></pre>
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider></v-divider>
            </div>
            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.access ? 'text-wrap' : 'text-wrap red--text'"
                v-text="campInfoFields.extra.access"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.access"></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-dialog v-model="successDialog" width="500">
          <v-alert type="success"
            >提交成功，请等待审核/Submitted successfully, Please wait for reviews.</v-alert
          >
          <v-btn color="primary" @click="successDialog = false">OK</v-btn>
        </v-dialog>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-text v-if="infoStatus == 'saved' || infoStatus == 'returned'">
            <v-checkbox
              v-model="statementAgreement"
              label="本人承诺以上情况属实，如有不实之处，愿意承担相应责任，包括但不限于被取消此夏令营营员资格以及因此带来的一切后果。 I declare that the information provided by me on the above form is true and correct to the best of my knowledge. I also confirm that in the event of any information provided by me is not true and incorrect, Tsinghua University will be well within its right to take necessary action including but not limited to the cancellation of my eligibility for participation of the summer camp and any consequences due to that."
              required
            ></v-checkbox>
            <v-card-actions class="justify-center" v-if="waiting">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
            <v-card-actions class="justify-center" v-else>
              <v-btn color="green" to="/apply/edit" dark> 返回编辑 </v-btn>
              <v-btn :disabled="!statementAgreement" color="primary" @click="handleSubmit()">
                确认提交
              </v-btn>
            </v-card-actions>
          </v-card-text>

          <v-card-text v-if="infoStatus == 'submitted' || infoStatus == 'passed'">
            <v-card-actions class="justify-center" v-if="waiting">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
            <v-card-actions class="justify-center" v-else>
              <v-btn type="submit" color="primary" @click="handlePrint()"> 打印 </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import consts from "@/consts";

export default {
  data: () => ({
    waiting: false,
    campInfoFields: consts.CAMP_INFO,
    campInfoFiles: consts.CAMP_FILES,
    userCampInfo: {},
    infoStatus: "",
    coreCurriculum: [],
    userFiles: {
      id_photo: null,
      resume: null,
      transcript: null,
      ranking_certificate: null,
      english_exams: null,
      other_docs: null,
    },
    degreeTypes: ["double", "second", "minor"],
    recommanderTypes: ["a", "b", "c"],
    genderList: {
      1: "男/Male",
      2: "女/Female",
    },
    curriculumHeaders: consts.CURRICULUM_HEADERS.slice(0, 5),
    statementAgreement: false,
    successDialog: false,
    preStyle: {
      "white-space": "pre-wrap",
      "word-break": "keep-all",
    },
  }),
  created() {
    this.getCampInfo();
  },
  computed: {},
  methods: {
    getCampInfo() {
      requests
        .get(`/api/camp/info`)
        .then((res) => {
          const info = res.data.info;
          this.userCampInfo = info;
          this.infoStatus = res.data.status;
          this.coreCurriculum = JSON.parse(info.core_curriculum);
          this.getUserFiles();
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.$router.push("/apply/edit");
          } else {
            alert(err.response.data.detail);
          }
        });
    },
    getUserFiles() {
      requests
        .get(`/api/users/files`)
        .then((res) => {
          const files = res.data.files;
          Object.keys(this.userFiles).forEach((k) => {
            const id = this.userCampInfo[k];
            if (id) {
              this.userFiles[k] = files[id];
            }
          });
        })
        .catch(() => {
          console.log("get user files error!");
        });
    },
    recommendLetterStatus(seq) {
      const current = this.userCampInfo["recommander_" + seq + "_letter"];
      const email = this.userCampInfo["recommander_" + seq + "_email"];
      if (current) {
        return "已上传/uploaded";
      }
      if (email == "~") {
        return "等待邮寄纸质推荐信并由教务上传/Waiting for recommend letter delivery";
      }
      if (this.infoStatus == "submitted") {
        return "已向推荐人发送邮件，等待上传/mail sent to recommander, waiting for upload";
      } else {
        return "提交时会自动向推荐人发送邮件并邀请上传/mail will be sent to recommander when submit";
      }
    },
    handleSubmit() {
      this.waiting = true;
      requests
        .post(`/api/camp/submit`)
        .then(() => {
          this.waiting = false;
          this.successDialog = true;
          this.getCampInfo();
        })
        .catch((err) => {
          this.waiting = false;
          alert(err.response.data.detail);
        });
    },
    forceFileDownload(res, title) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
      const link = document.createElement("a");
      link.href = url;
      link.download = title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handlePrint() {
      this.waiting = true;
      requests
        .get(`/api/camp/print`, { responseType: "blob" })
        .then((res) => {
          this.waiting = false;
          const title = `清华大学天文系夏令营申请表-${this.userCampInfo.name_cn}.pdf`;
          this.forceFileDownload(res, title);
        })
        .catch(() => {
          this.waiting = false;
          alert("生成 pdf 失败，请重试/failed to generate pdf, pls retry");
        });
    },
  },
};
</script>
